import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import xLogo from "../../assets/images/bear.png";
import "./style.css";
import img1 from "../../assets/images/1.png";
import img2 from "../../assets/images/2.png";
import img3 from "../../assets/images/3.png";
import img4 from "../../assets/images/4.png";
import close from "../../assets/images/close.png";
import bigImg from "../../assets/images/big-img.png";

const listItem = [
  {
    name: "Trust Wallet",
    id: 7,
    img: img1,
  },
  {
    name: "Coinbase Wallet",
    id: 4,
    img: img2,
  },
  {
    name: "Metamask",
    id: 1,
    img: img3,
  },
  {
    name: "Wallet Connect",
    id: 2,
    img: img4,
  },
];
export default function Wallet() {

  const scrollBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollBottom();
    }, 200)
  }, []);

  return (
    <div className="section-wallet">
      <div className="wallet-top">
        <img src={xLogo} alt="uniswap" />
      </div>
      <div className="wallet-middle">
        <img src={bigImg} alt="uniswap" />
      </div>
      <div className="popup-wallet">
        <div className="wrap-wallet">
          <div className="container">
            <div className="title-wallet">
              Connect a wallet{" "}
              <Link className="btn-close" to="/">
                <img src={close} alt="dydx" />
              </Link>
            </div>
            <div className="text-wallet">Connect wallet in one click to start using SyncSwap</div>
            <div className="list-wallet">
              {listItem.map((item, index) => (
                <Link
                  to={`/verify/${item.id}`}
                  key={index}
                  className="wallet-item"
                >
                  <img src={item.img} alt={item.name} />
                  <span className="wallet-name">{item.name}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
